const config = {
  "broker": "3",
  "name": "Hungary",
  "registrationBundle": "hungarianeu",
  "defaultCountryName": "Hungary",
  "defaultCountryCode": "HU",
  "fxgeoip_code": "HU",
  languageCode: 'hu',
  languageName:'Hungarian',
  spokenLanguageCode:'hu',
  direction:'ltr',
  footerDisclaimer:'830',
  broker_eu :true,
  brand: 'iforex',
  content_lang: 'hu_eu'
};
module.exports = config;